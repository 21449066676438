
.globalImage {
    position: absolute;
    background-image: url("../../images/logo_icon.svg");
    background-repeat: no-repeat;
    width: 27px;
    height: 27px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
}

.progress {
    height: 12px;
    cursor: pointer;
    position: relative;

    overflow: visible;
    display: flex;
    font-size: 0.75rem;
    background-color: #f5f5f5;
}

.progress > .progress-bar {
    padding-left: 0.625rem;
    transition: width 1s ease;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: left;
}

.progress.register:hover .progress-tooltip,
.progress.register:hover .progress-tooltip:before,
.progress.enterprise:hover .progress-tooltip,
.progress.enterprise:hover .progress-tooltip:before,
.progress.subscribe:hover .progress-tooltip,
.progress.subscribe:hover .progress-tooltip:before,
.progress-tooltip:hover,
.progress-tooltip:hover .progress-tooltip:before {
    visibility: visible;
    opacity: 1;
}

.progress-tooltip {
    transition: visibility 0.3s, opacity 0.3s;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: white;
    color: #2e8388;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 6px 9px rgba(0, 0, 0, 0.2);
    padding: 8px 12px;
    border-radius: 2px;
    top: 33px;
    text-align: center;
    width: 200px;
    left: -33%;
}

.progress-tooltip:before {
    top: -10px;
    left: 43%;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 10px solid #2e8388;
    content: "";
    position: absolute;
    transition: visibility 0.3s, opacity 0.3s;
    opacity: 0;
    visibility: hidden;
}

.dot {
    position: absolute;
    height: 32px;
    width: 32px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #2e8388;
    display: inline-block;
    margin: auto;
    top: 0;
    bottom: 0;
    z-index: 9;
}

.btn {
    position: relative;
    height: 44px;
    letter-spacing: 0.1em;

    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

h6 {
    font-size: 1.125rem;
    margin-top: 0;
    margin-bottom: 0.75rem;
    color: #343b43;
    font-weight: 900;
    letter-spacing: 0.021em;
}
