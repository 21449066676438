@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.tel-group .vue-tel-input.custom-tel-input {
    border: 1px solid #e4e4e4;

    &:focus-within {
        box-shadow: none;
        border: 1px solid #e4e4e4;
    }

    &.is-invalid {
        border: 1px solid #ff5252;
    }
}
