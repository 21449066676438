@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.form-help {
    font-size: $font-size-md;
    color: $info;

    i {
        vertical-align: bottom;
    }
}
