@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.form-control.input-field {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    height: 100%; // For multiline values like textareas
    cursor: text;

    &:not(.is-invalid) {
        border: none;
    }

    &:hover {
        border: 1px dashed #888;
    }
}
