
.loading-overlay {
    position: relative;
}

.spinner-section {
    background-color: #fff;
    box-shadow: 0 0 2rem 2rem #fff;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
