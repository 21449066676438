@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.custom-control-label {
    padding-top: 3px;
    font-size: 1rem;
}

.custom-checkbox .custom-control-input ~ .custom-control-label::before {
    border: 1px solid;
}
